:root {
  --content-font-size: 15px;

  /* 22px by default, 22/15=1.4666... */
  --content-line-height: 1.47em;
}

.font-size-smallest {
  --content-font-size: 12px;
}

.font-size-small {
  --content-font-size: 13px;
}

.font-size-medium {
  --content-font-size: 14px;
}

.font-size-large {
  --content-font-size: 15px;
}

.font-size-largest {
  --content-font-size: 16px;
}

.status__info,
.status__prepend,
.status__content,
.status__content__read-more-button,
.status__content__translate-button,
.content-warning,
.translate-button,
.edit-indicator__content,
.reply-indicator__content,
.notification__message,
.notification__report__details,
.notification-group__main,
.notification-group__main__header__label,
.notification-group__main__additional-content,
.notification-group__embedded-status__account,
.notification-ungrouped__header {
  font-size: var(--content-font-size) !important;
  line-height: var(--content-line-height) !important;

  .emojione {
    min-width: var(--content-line-height) !important;
    height: var(--content-line-height) !important;
  }

  .poll {
    /* 14px */
    font-size: inherit !important;
  }
}

.status__info {
  .status__relative-time,
  .status__display-name {
    font-size: inherit;
    line-height: inherit;
  }
}

.account__wrapper {
  .account__display-name {
    font-size: inherit;
  }
}
